<template>
  <div class="container">

    <h1>Le jeu de famille à partager</h1>

    <!-- <h1>Définissez vos charactères, vos lieux et tous vos paramètres de vos familles</h1> -->
    <!-- <button @click="goToVisualView" class="nav-button styled-button">Go to Visual View</button> -->

    <div v-if="isValid">
      <div class="game-creation-header">
        <h1 class="game-title">Configurer votre propre jeu de 7 familles</h1>
        <div class="info-container">
          <div class="info-item">
            <div class="info-label">Nombre de familles :</div>
            <div class="info-value">{{ familyCount }}</div>
          </div>
          <div class="info-item">
            <div class="info-label">Nombre de personnages :</div>
            <div class="info-value">{{ characterCount }}</div>
          </div>
        </div>
      </div>

      <p>Personnalisez vos familles à votre goût !</p>

      <!-- 
      <div v-if="step === 1" class="form-step">
        <label for="familyCount">Nombre de familles :</label>
        <input type="number" id="familyCount" v-model.number="familyCount" min="1" class="input-field" />
        <button @click="nextStep" class="form-button">Suivant</button>
      </div>

      <div v-if="step === 2" class="form-step">
        <label for="characterCount">Nombre de personnages :</label>
        <input type="number" id="characterCount" v-model.number="characterCount" min="1" class="input-field" />
        <button @click="nextStep" class="form-button">Suivant</button>
      </div>
      -->
      
      <div v-if="step === 1" class="form-step">

        <div class="info-container">
          <div class="info-item">
            <div class="info-label">Exemple 1 :</div>
            <div class="info-value"><b>Les Elfes Sylvestres :</b><br/><br/> Les Elfes Sylvestres vivent dans les profondeurs des forêts anciennes. Ils sont connus pour leur sagesse, leur agilité et leur connexion profonde avec la nature. Ils protègent les bois sacrés et les créatures qui y vivent.</div>
          </div>
          <div class="info-item">
            <div class="info-label">Exemple 2 :</div>
            <div class="info-value"><b>Les Voyageurs du Futur :</b> <br/><br/> Les Voyageurs du Futur viennent d'un monde lointain, où la technologie a atteint des sommets inimaginables. Ils explorent le passé et le futur pour apprendre et prévenir des catastrophes.</div>
          </div>
        </div>
        
        <br/>

        <div v-for="(family, index) in families" :key="index" class="family-form">
          <label>Besoin d'inspiration pour la famille {{ family.name || index + 1 }} ?</label>
          <div class="radio-group">
            <label class="radio-option">
              <input type="radio" :name="'familyInspiration' + index" @change="setInspiration('family', index, true)" />
              OUI
            </label>
            <label class="radio-option">
              <input type="radio" :name="'familyInspiration' + index" @change="setInspiration('family', index, false)" />
              NON
            </label>
          </div>
          <label :for="'familyName' + index"><b>Nom de la famille {{ index + 1 }} :</b></label>
          <input
            type="text"
            :id="'familyName' + index"
            v-model="family.name"
            @input="checkLength(family, 'name', 25)"
            class="input-field"
            required
          />
          <span v-if="family.nameError" class="error">{{ family.nameError }}</span>
          <label :for="'familyDescription' + index">Description de la famille {{ index + 1 }} :</label>
          <textarea
            :id="'familyDescription' + index"
            v-model="family.description"
            class="input-field"
            style="height: 100px;"
            required
          ></textarea>
        </div>
        <button @click="nextStep" class="form-button">Suivant</button>
      </div>
      
      <div v-if="step === 2" class="form-step">

        <div class="info-container">
          <div class="info-item">
            <div class="info-label">Exemple 1 :</div>
            <div class="info-value"><b>Elyndor, le Gardien des Arbres</b><br/><br/>Elyndor est le protecteur des grands arbres ancestraux. Il possède le pouvoir de communiquer avec les plantes et de contrôler la végétation. Sa sagesse est respectée par tous les habitants de la forêt.</div>
          </div>
          <div class="info-item">
            <div class="info-label">Exemple 2 :</div>
            <div class="info-value"><b>Nova, l'Ingénieure du Temp</b><br/><br/>Nova est une scientifique brillante, capable de réparer et d'améliorer les machines temporelles. Son expertise est cruciale pour des voyages sûrs et précis à travers les âges.</div>
          </div>
        </div>

        <br/>

        <div v-for="(character, index) in characters" :key="index" class="character-form">
          <label>Besoin d'inspiration pour le personnage {{ character.name || index + 1 }} ?</label>
          <div class="radio-group">
            <label class="radio-option">
              <input type="radio" :name="'characterInspiration' + index" @change="setInspiration('character', index, true)" />
              OUI
            </label>
            <label class="radio-option">
              <input type="radio" :name="'characterInspiration' + index" @change="setInspiration('character', index, false)" />
              NON
            </label>
          </div>
          <label :for="'characterName' + index">Nom du personnage {{ index + 1 }} :</label>
          <input
            type="text"
            :id="'characterName' + index"
            v-model="character.name"
            @input="checkLength(character, 'name', 30)"
            class="input-field"
            required
          />
          <span v-if="character.nameError" class="error">{{ character.nameError }}</span>
          <label :for="'characterDescription' + index">Détail du personnage {{ index + 1 }} :</label>
          <textarea
            :id="'characterDescription' + index"
            v-model="character.description"
            class="input-field"
            style="height: 100px;"
            required
          ></textarea>
        </div>
        <button @click="nextStep" class="form-button">Suivant</button>
        
        <button @click="reloadPage" class="form-button">Recommencer</button>
      </div>
      
      <div v-if="step === 3" class="form-step">

        <div class="info-container">
          <div class="info-item">
            <div class="info-label">Exemple de style :</div>
            <div class="info-value">Cartoon, japonais, fantaisiste, futuriste</div>
          </div>
          <div class="info-item">
            <div class="info-label">Exemple d'information générale:</div>
            <div class="info-value">Une carte au trésor en image de fond.</div>
          </div>
        </div>

        <br/>

        <label for="generalStyle">Style général :</label>
        <input type="text" id="generalStyle" v-model="generalStyle" class="input-field" require/>
        <label for="generalInfo">Information générale :</label>
        <textarea type="text" id="generalInfo" v-model="generalInfo" class="input-field" style="height: 100px;" require></textarea>

        Inscrivez votre meilleur email pour être tenu informé dès que votre famille est prête. <br/>
        <label for="email">Email :</label>
        <input type="email" id="email" v-model="email" class="input-field" required>

        <div v-if="commandeEffectuee" class="message">
          <!-- <ProgressBar :progress="loadingProgress" /> -->
          La création de vos cartes est lancée ...<br/>
          <p>
            {{ message }}
            Vous allez recevoir un email de confirmation une fois que les images seront prêtes dans environ {{ estimatedTimeInMinutes }} minutes.
          </p>
        </div>
        
        <button @click="generate" class="form-button">Générer</button>

        <button @click="reloadPage" class="form-button">Recommencer</button>
      </div>
    </div>
    <div v-else>
      <p class="error-message">Une erreur est intervenue dans la configuration de votre jeu, merci de recommencer ...</p>
    </div>
    
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Cookies from 'js-cookie';

export default {
  name: 'SettingsView',

  propos: {
    familyCount: {
      type: Number,
      required: true
    },
    characterCount: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      imageUrl: null,
      imageData: null,
      backgroundImagesGenerated: null,
    };
  },
  created() {
    this.imageData = this.$route.params.imageData;
    this.backgroundImagesGenerated = this.$route.params.backgroundImagesGenerated;
  },
  setup() {
    const baseUrl = 'http://127.0.0.1:8000'; //local
    //const baseUrl = 'https://cortus7families.fr/'; 
    const step = ref(1);
    const families = ref([]);
    const characters = ref([]);
    const generalStyle = ref('');
    const generalInfo = ref('');
    const email = ref('');
    const commandeEffectuee = ref(false);
    const message = ref('');

    const showVisualView = ref(false);

    const route = useRoute();
    const router = useRouter();

    const familyInspiration = [
      { name: "Les Forts", description: "Une famille unie et déterminée à surmonter les obstacles." }, 
      { name: "Les Montagnards", description: "Des membres aux pouvoirs mystérieux provenant des montagnes." }, 
      { name: "Les Nobles", description: "Une lignée noble qui a régné sur des terres lointaines." }, 
      { name: "Les Inventeurs", description: "Connue pour ses inventions révolutionnaires." }, 
      { name: "Les Artisans", description: "Maîtres dans l'artisanat, des artisans chevronnés." }, 
      { name: "Les Aventuriers", description: "Toujours à la recherche de nouvelles aventures et découvertes." }, 
      { name: "Les Légendaires", description: "Leurs exploits sont racontés dans des légendes." }, 
      { name: "Les Secrets", description: "Peu de gens connaissent leurs secrets." }, 
      { name: "Les Défenseurs", description: "Connue pour défendre les plus faibles." }, 
      { name: "Les Sages", description: "Des conseillers sages et respectés." }
    ];

    const characterInspiration = [
      { name: "Le Guerrier", description: "Intrépide et toujours prêt pour la bataille." }, 
      { name: "Le Magicien", description: "Maître des arts mystiques et magiques." }, 
      { name: "Le Voyageur", description: "Toujours en quête de nouvelles expériences." }, 
      { name: "Le Sage", description: "Connu pour ses conseils avisés et sa grande sagesse." }, 
      { name: "Le Farceur", description: "Un personnage rusé et farceur." }, 
      { name: "Le Silencieux", description: "Silencieux mais toujours prêt à défendre." }, 
      { name: "Le Solitaire", description: "Un être solitaire, toujours en déplacement." }, 
      { name: "Le Leader", description: "Charismatique et leader naturel." }, 
      { name: "Le Rêveur", description: "Audacieux et rêveur, toujours avec des idées innovantes." }, 
      { name: "Le Guérisseur", description: "Bienveillant et capable de soigner les blessures les plus graves." }
    ];

    const setInspiration = (type, index, needsInspiration) => {
      if (needsInspiration) {
        if (type === 'family' && index < familyInspiration.length) {
          families.value[index].name = familyInspiration[index].name;
          families.value[index].description = familyInspiration[index].description;
        } else if (type === 'character' && index < characterInspiration.length) {
          characters.value[index].name = characterInspiration[index].name;
          characters.value[index].description = characterInspiration[index].description;
        }
      } else {
        if (type === 'family') {
          families.value[index].description = '';
        } else if (type === 'character') {
          characters.value[index].description = '';
        }
      }
    };

    const generateRandomId = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    // Références pour les IDs
    const userId = ref(generateRandomId(40, 200));
    const orderId = ref(generateRandomId(100, 400));
    
    const loadingProgress = ref(0) // Variable pour la progression de la barre, ajustez selon vos besoins

    const familyCount = ref(parseInt(route.params.familyCount));
    const characterCount = ref(parseInt(route.params.characterCount));
    
    const validCombinations = [
      { familyCount: 2, characterCount: 2 },
      { familyCount: 7, characterCount: 5 },
      { familyCount: 7, characterCount: 7 },
      { familyCount: 10, characterCount: 5 },
      { familyCount: 10, characterCount: 7 }
    ];

    const isValid = validCombinations.some(combination => 
      combination.familyCount === familyCount.value && 
      combination.characterCount === characterCount.value
    );

    // Méthode pour calculer le temps estimé en minutes
    const estimatedTimeInMinutes = computed(() => {
      const totalSeconds = familyCount.value * characterCount.value * 30;
      return (totalSeconds / 60).toFixed(2); // Arrondi à 2 décimales
    });

    // Utilisation de onMounted pour accéder aux paramètres de route après le montage du composant
    onMounted(() => {
    
      families.value = Array.from({ length: familyCount.value }, () => ({ name: '', description: '' }));
      characters.value = Array.from({ length: characterCount.value }, () => ({ name: '', description: '' }));

    });

    const nextStep = () => {
      step.value++;
    };

    const generate = async () => {
      const payload = {
        user_id: userId.value,
        order_id: orderId.value,
        families: families.value,
        characters: characters.value,
        generalStyle: generalStyle.value,
        generalInfo: generalInfo.value,
        email: email.value,
      };
      try {
        loadingProgress.value = 0 // Réinitialiser la progression avant l'appel
        for (let i = 0; i <= 100; i += 10) {
              loadingProgress.value = i
        }

        commandeEffectuee.value = true

        const response = await fetch(`${baseUrl}/api/generate-images/`, {
          method: 'POST',
          headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        // Réinitialiser la progression après la fin
        loadingProgress.value = 100;
        
        if (response.ok) {
          const data = await response.json();
        
          const taskId = data.task_id;
          console.log('taskId: ', taskId);
          message.value = `Votre référence est ${taskId}.`;

          showVisualView.value = true
        } else {
          //alert('Une erreur s\'est produite lors de l\'envoi des données.');
          console.error('Erreur lors de l\'envoi des données.');
        }

      } catch (error) {
        console.error('Erreur lors de l\'envoi des données :', error);
      }
    };

    return {
      step,
      families,
      characters,
      generalStyle,
      generalInfo,
      email,
      nextStep,
      commandeEffectuee,
      generate,
      //userId,
      familyCount,
      characterCount,
      router,
      showVisualView,
      estimatedTimeInMinutes, 
      message,
      isValid,
      setInspiration
    };
  },
  methods: {
    checkLength(item, field, maxLength) {
      if (item[field].length > maxLength) {
        item[field + 'Error'] = `Le nom ne doit pas dépasser ${maxLength} caractères.`;
      } else {
        item[field + 'Error'] = '';
      }
    },
    /*
    simulateLoading() {
      this.commandeEffectuee = true;
      let progress = 0
      const interval = setInterval(() => {
        progress += 1
        this.loadingProgress = progress
        if (progress >= 100) {
          clearInterval(interval)
        }
      }, 500)
    },
    */
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style>
.container {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  font-family: Arial, sans-serif;
}

.nav-button {
  margin-bottom: 20px;
}

.form-step {
  margin: 20px 0;
}

.family-form, .character-form {
  margin-bottom: 20px;
}

.input-field {
  display: block;
  width: 100%;
  height: 50px;
  padding: 8px;
  margin: 5px 0;
  box-sizing: border-box;
}

textarea{
  height: 100px;
}

.form-button {
  margin: 10px;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.styled-button {
  padding: 10px 20px; /* Adjust padding as needed */
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Remove default border */
  border-radius: 25px; /* Make the button rounded */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 1rem; /* Adjust font size */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transitions */
}

.styled-button:hover {
  background-color: #0056b3; /* Darker background on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.message {
  font-size: 1.2rem;
  font-weight: bold;
  color: green;
  margin-top: 10px;
}

.game-creation-header {
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.game-title {
  margin-bottom: 10px;
}

.info-container {
  display: flex;
  justify-content: space-around;
}

.info-item {
  width: 300px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .info-container {
    flex-direction: column;
    align-items: center;
  }
  .info-item {
    width: 90%;
    margin-bottom: 20px;
  }
}

.info-label {
  font-weight: bold;
}

.info-value {
  margin-top: 5px;
  font-size: 18px;
}

.message-container {
  margin-top: 20px;
  padding: 10px;
  background-color: #dff0d8;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  color: #155724;
}

.error-message {
  margin: 2rem;
  padding: 1rem;
  background-color: #FFEBB3; /* Blond */
  color: #007bff; 
  border-radius: 0.5rem; /* Facultatif, pour arrondir les coins */
  font-weight: bold; /* Facultatif, pour mettre en valeur le texte */
  text-align: center; /* Facultatif, pour centrer le texte */
}

.radio-group {
  margin: 10px 0;
  display: flex;
  gap: 10px;
}

.radio-option {
  font-weight: bold;
  cursor: pointer;
}

.radio-option input[type="radio"] {
  margin-right: 5px;
}

.input-field {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
}
</style>