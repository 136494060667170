<template>
    <div class="cgu-view">
      <h1>Merci de votre confiance</h1>
      <p>Insérez ici le texte complet de vos Conditions Générales de Vente.</p>
      <p>Ce texte peut inclure toutes les informations légales et les termes et conditions de vos services ou produits.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CGUView',
  };
  </script>
  
  <style scoped>
  .cgu-view {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  p {
    line-height: 1.6;
    margin-bottom: 15px;
  }
  </style>
  