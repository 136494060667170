<template>
    <div class="container">
      <h1>{{ title }}</h1>
      <h2>{{ subtitle }}</h2>
      <p>{{ detail }}</p>
      
      <div class="alert">
        <p><b>Prochaine livraison</b>: le <b>{{ nextAlertDate }}</b> du mois en cours. Il reste <b>{{ hoursLeft }}</b> heure(s) pour faire partie de la prochaine livraison.</p>
      </div>
      
      <div class="gallery">
        <h3>Galerie de Photos</h3>
        <div class="photos">
          <img 
            v-for="(photo, index) in photos" 
            :key="index" 
            :src="photo" 
            alt="Exemple photo" 
            class="photo" 
            @mouseover="zoomIn($event)"
            @mouseout="zoomOut($event)"
            @click="zoomIn($event)"
          />
        </div>
      </div>

      <div class="info-container">
        <div class="info-card">
          <h2>Information Importante</h2>
          <p>
            La génération d'images est effectuée par une intelligence artificielle avancée. 
            Chaque image est soigneusement créée pour répondre à vos besoins uniques.
          </p>
          <p>
            Malgré les précautions prises pour garantir la qualité et la pertinence des images générées, 
            il est possible que certaines créations ne correspondent pas exactement à vos attentes ou 
            puissent contenir des éléments inappropriés, indépendamment de notre volonté.
          </p>
          <p>
            Les impressions de vos créations sont réalisées en France avec les meilleurs standards de qualité. 
            Vous recevrez un produit final de haute qualité, prêt à être apprécié.
          </p>
        </div>
      </div>

      <div class="form-container">
        <div class="offers">
          <h3>Choisissez une offre:</h3>
          <div class="offers-container">
            <div class="offer-card" v-for="(offer, index) in offers" :key="index" :class="{ 'best-seller': offer.bestSeller, 'our-selection': offer.OurSelection }">
              <div v-if="offer.bestSeller" class="best-seller-badge">Meilleur vente</div>
              <div v-if="offer.OurSelection" class="our-selection-badge">Notre sélection</div>
              <label><b>{{ offer.name }} <div v-if="!offer.OurSelection">- {{ offer.price }}€</div></b></label>
              <ul>
                <li v-for="(value, valueIndex) in offer.values" :key="valueIndex">
                  <span class="bullet"></span>{{ value }}
                </li>
              </ul>
              <a :href="offer.stripeLink" class="pay-button" :class="{ 'tester-button': offer.buttonText === 'Tester' }" @click.prevent="pay(offer)">{{ offer.buttonText }}</a>
            </div>
          </div>
        </div>
      </div>
      
      <HowToUse  />
  
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { ref, computed } from 'vue';
  import Cookies from 'js-cookie';
  import HowToUse from './HowToUse.vue'

  // Configurer le baseURL pour axios
  //axios.defaults.baseURL = 'http://127.0.0.1:8000/'; // Remplacez par l'URL de votre API
  axios.defaults.baseURL = 'https://cortus7families.fr/'
  
  // Récupérer le token CSRF du cookie
  const csrftoken = Cookies.get('csrftoken');
  if (!csrftoken) {
    console.error('CSRF token is not defined in cookies');
  }

  // Ajouter le CSRF token à toutes les requêtes axios
  axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
  //console.log('csrftoken: ', csrftoken);

  //import $ from 'jquery'; // npm install jquery
  //let csrfmiddlewaretoken = $("input[name='csrfmiddlewaretoken']").val();
  //console.log('csrfmiddlewaretoken: ', csrfmiddlewaretoken);
  /*
  const headers = {
  'Content-Type': 'application/json',
  'X-CSRFToken': csrfmiddlewaretoken
  };
  */

  //console.log('headers: ', headers);
  
  export default {
    name: 'MonComposant',
    components: {
      HowToUse,
    },
    mounted() {
      this.logUserActivity();
    },
    setup() {
      const title = ref('Cortus 7 families');
      const subtitle = ref('Créer mon propre jeu de 7 familles');
      const detail = ref('Vous avez envie de jouer avec votre propre famille, créer le jeu qui vous convient en générant vos images à partir de vos critères.');
      const today = new Date();
      const nextAlertDate = ref(today.getDate() <= 15 ? '15' : '30');
      const hoursLeft = computed(() => {
        const currentHour = today.getHours();
        const alertDate = nextAlertDate.value === '15' ? 15 : 30;
        const remainingHours = alertDate - today.getDate();
        if (remainingHours < 0) {
          return 0;
        } else {
          return remainingHours * 24 - currentHour;
        }
      });
  
      //const photos = ref(Array.from({ length: 15 }, (_, i) => `https://via.placeholder.com/150?text=Photo+${i + 1}`));
  
      const photos = ref([
      require('@/assets/photos/aigle_La-fille_Au-format-cartoon.png'),
      require('@/assets/photos/aigle_La-grand-mere_Au-format-cartoon.png'),
      require('@/assets/photos/aigle_Le-fils_Au-format-cartoon.png'),
      require('@/assets/photos/chien_Le-grand-pere_Au-format_cartoon.png'),
      require('@/assets/photos/chien_Le-grand-pere_Au-format-cartoon.png'),
      require('@/assets/photos/cochon_La-fille_Au-format-cartoon.png'),
      require('@/assets/photos/cochon_Le-papa_Au-format-cartoon.png'),
      require('@/assets/photos/crocodile_La-fille_Au-format-cartoon.png'),
      require('@/assets/photos/crocodile_La-grand-mere_Au-format_cartoon.png'),
      require('@/assets/photos/crocodile_La-grand-mere_Au-format-cartoon.png'),
      require("@/assets/photos/Train_L'aventurier_ZKk71Ged4qvql4M0.png"), 
      require("@/assets/photos/Avion_L'aventurier_4NMozeWOdEK4e0me.png"), 
      require("@/assets/photos/Avion_L'aventurier_rVk3068v4oozv8mY.png"), 
      require("@/assets/photos/Avion_L'aventurier_VDMxqD0nbz9x1gyP.png"), 
      require("@/assets/photos/Avion_L'aventurier_VWmlobgN9QALGokP.png"), 
      require("@/assets/photos/Avion_L'aventurier_xBkw8QLnNddPe4mq.png"), 
      require("@/assets/photos/Avion_L'aventurier_YbknRzve4LNDaXk5.png"), 
      require("@/assets/photos/Train_L'aventurier_jOyeQpl6rpwQoWya.png"), 
      require("@/assets/photos/Train_L'aventurier_JWk15aV0rLAoprkQ.png"),
      require('@/assets/photos/Men_Basketball_Cartoon.png'),
      require('@/assets/photos/Men_BMX_Cartoon.png'),
      require('@/assets/photos/ours_Le-grand-pere_Au-format-cartoon.png'),
      require('@/assets/photos/ours_Le-papa_Au-format-cartoon.png'),
      require('@/assets/photos/tigre_Le-fils_Au-format-cartoon.png'),
      require('@/assets/photos/tigre_Le-grand-pere_Au-format-cartoon.png'),
      require('@/assets/photos/Woman_Artistic-Gymnastics_Cartoon.png'),
      require('@/assets/photos/Woman_Basketball_Cartoon.png'),
      require('@/assets/photos/Woman_BMX_Cartoon.png'),
    ]);
  
    /*
      const offers = ref([
        { name: '35 cartes', price: 9, values: ['7 familles de 5 membres', 'Cartes de 250 grs', 'Verso universel', 'Carte des familles et membres', '+5€ de livraison'], stripeLink: 'https://stripe.com/pay/offer1' },
        { name: '49 cartes', price: 15, values: ['7 familles de 7 membres', 'Cartes de 250 grs', 'Verso universel', 'Carte des familles et membres', '+5€ de livraison'], stripeLink: 'https://stripe.com/pay/offer1' },
        { name: '50 cartes', price: 18, values: ['10 familles de 5 membres', 'Cartes de 250 grs', 'Verso universel', 'Carte des familles et membres', '+5€ de livraison'], stripeLink: 'https://stripe.com/pay/offer1' },
        { name: '70 cartes', price: 24, values: ['10 familles de 7 membres', 'Cartes de 250 grs', 'Verso universel', 'Carte des familles et membres', '+5€ de livraison'], stripeLink: 'https://stripe.com/pay/offer2' },
      ]);
    */
      const zoomIn = (event) => {
        event.target.style.transform = 'scale(1.7)';
        event.target.style.transition = 'transform 0.3s';
      };
  
      const zoomOut = (event) => {
        event.target.style.transform = 'scale(1)';
        event.target.style.transition = 'transform 0.3s';
      };
  
      return {
        title,
        subtitle,
        detail,
        nextAlertDate,
        hoursLeft,
        photos,
        zoomIn,
        zoomOut,
      };
    },
    data() {
      return {
        form: {
          prenom: '',
          nom: '',
          email: '',
          telephone: '',
          rue: '',
          ville: '',
          codePostale: '',
          infoSupplementaire: '',
          codePromo: '',
          offer: {}  // Initialisation de l'objet offer dans le formulaire
        },
        emailError: '',
        offers: [
          {
            name: '4 cartes',
            price: 0,
            values: ['2 familles de 2 membres', 'Pas d`impression'],
            stripeLink: 'https://cortus7families.fr/app2/2/2',
            families: 2,
            people: 2,
            buttonText: 'Tester',
            bestSeller: false,
            OurSelection: false
          },
          {
            name: '35 cartes',
            price: 7,
            values: ['7 familles de 5 membres', 'Pas d`impression'],
            stripeLink: 'https://buy.stripe.com/aEU0409EWe4kd5m6oC',
            families: 7,
            people: 5,
            buttonText: 'Payer',
            bestSeller: false,
            OurSelection: false
          },
          {
            name: '35 cartes',
            price: 18,
            values: ['7 familles de 5 membres', 'Cartes de 250 grs', 'Carte des familles et membres', '+5€ de livraison'],
            stripeLink: 'https://buy.stripe.com/8wM7ws6sKf8od5m5kx',
            families: 7,
            people: 5,
            buttonText: 'Payer',
            bestSeller: false,
            OurSelection: false
          },
          {
            name: '49 cartes',
            price: 24,
            values: ['7 familles de 7 membres', 'Cartes de 250 grs', 'Carte des familles et membres', '+5€ de livraison'],
            stripeLink: 'https://buy.stripe.com/aEUcQM3gy1hy2qI28i',
            families: 7,
            people: 7,
            buttonText: 'Payer',
            bestSeller: false,
            OurSelection: false
          },
          {
            name: '50 cartes',
            price: 24,
            values: ['10 familles de 5 membres', 'Cartes de 250 grs', 'Carte des familles et membres', '+5€ de livraison'],
            stripeLink: 'https://buy.stripe.com/4gwcQMeZg4tKghy9AM',
            families: 10,
            people: 5,
            buttonText: 'Payer',
            bestSeller: true,
            OurSelection: false
          },
          {
            name: '70 cartes',
            price: 29,
            values: ['10 familles de 7 membres', 'Cartes de 250 grs', 'Carte des familles et membres', '+5€ de livraison'],
            stripeLink: 'https://buy.stripe.com/9AQ6so04mgcsd5m3cn',
            families: 10,
            people: 7,
            buttonText: 'Payer',
            bestSeller: false,
            OurSelection: false
          },
          {
            name: 'Offre entreprise',
            price: "",
            values: ['Vous souhaitez offrir un cadeau remarquable à vos clients ?', 'Vous voulez faire un cadeau à vos équipes ?', 'Vous voulez faire une campagne promotionnelle ?'],
            stripeLink: 'https://cortus7families.fr/entreprise',
            families: 0,
            people: 0,
            buttonText: 'Demander à être rappelé',
            bestSeller: false,
            OurSelection: true
          },
          {
            name: 'Mon jeu de 7 familles à partir de vraies photos',
            price: "",
            values: ['Intéressé par votre jeu de 7 familles à partir de vos photos ?', 'Envie de faire de beaux cadeaux à vos proches ?', "Vous voulez vous retrouver dans d'inscroyables paysages ?", "Inscrivez-vous sur notre liste d'attente."],
            stripeLink: 'https://cortus7families.fr/inpainting',
            families: 0,
            people: 0,
            buttonText: "S'inscre sur la liste d'attente",
            bestSeller: false,
            OurSelection: true
          }
        ],
        selectedOffer: null,  // Pour stocker l'offre sélectionnée
        user_id: '',
        nb_families: '',
        nb_people: '',
      };
    },
    methods: {
      async submitForm(offer) {
        
        /* Array : 
        this.form.offer = { // Met à jour this.form.offer avec les détails de l'offre sélectionnée
          id: offer.id,
          name: offer.name,
          price: offer.price
        };
        */
        
        // Convertir this.form.offer en une chaîne JSON
        let offerString = JSON.stringify({id: offer.id, name: offer.name, price: offer.price});
        this.form.offer = offerString;

        try {
          console.log('this.form: ', this.form);
          const response = await axios.post('/api/userform/', this.form, {
            headers: {
              'X-CSRFToken': Cookies.get('csrftoken'),
              'Content-Type': 'application/json'
            }
          });
          //console.log('Form submitted successfully:', response.data);
          this.user_id = response.data.user_id;
          this.order_id = response.data.order_id;
        } catch (error) {
            if (error.response) {
                // Erreur renvoyée par le serveur avec un statut de réponse
                console.error('Error during payment process:', error.response.data);
                //console.error('Error data:', error.response.data);
                //console.error('Error status:', error.response.status);
                //console.error('Error headers:', error.response.headers);
              } else if (error.request) {
                // La requête a été faite mais aucune réponse n'a été reçue
                console.error('No response received:', error.request);
              } else {
                // Une erreur est survenue lors de la configuration de la requête
                console.error('Error setting up the request:', error.message);
              }
        }
      },
      async pay(offer) {
        
        try {
          //console.log(`Paiement pour ${offer.name} au prix de ${offer.price}€`);
          //console.log('lien: ', offer.stripeLink);
          //console.log('response user_id: ', this.user_id);
          //console.log(`Nb families/nb people: ${offer.families}/${offer.people}`);
          window.location.href = offer.stripeLink;
          //window.location.href = `http://localhost:8080/app/${this.user_id}/${this.order_id}/${offer.families}/${offer.people}`
        } catch (error) {
          console.error('Error during payment process:', error);
        }

      },

      async logUserActivity() {
        
        let latitude = null;
        let longitude = null;

        try {
          const ipResponse = await axios.get('https://api.ipify.org?format=json');
          const ipAddress = ipResponse.data.ip;
          
          const device = navigator.userAgent;
          const browser = navigator.appName;
          
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
              latitude = position.coords.latitude;
              longitude = position.coords.longitude;
              this.sendLog(ipAddress, device, browser, latitude, longitude);
            }, error => {
              console.error("Error getting location:", error);
              this.sendLog(ipAddress, device, browser, latitude, longitude);
            });
          } else {
            latitude = '';
            longitude = '';
            this.sendLog(ipAddress, device, browser, latitude, longitude);
          }
        } catch (error) {
          //console.error("Error logging user activity:", error);
          const ipAddress = '127.0.0.1';
          const device = navigator.userAgent;
          const browser = navigator.appName;
          latitude = '';
          longitude = '';
          this.sendLog(ipAddress, device, browser, latitude, longitude);
        }
      },
      async sendLog(ipAddress, device, browser, latitude, longitude) {
        try {
          await axios.post('/api/logs/', {
            ip_address: ipAddress,
            device: device,
            browser: browser,
            latitude: latitude,
            longitude: longitude
          }, {
            headers: {
              'X-CSRFToken': Cookies.get('csrftoken'),
              'Content-Type': 'application/json'
            }
          });
        } catch (error) {
          console.error("Error sending log data:", error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .gallery {
    margin-bottom: 20px;
  }
  
  .photos {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .photo {
    width: calc(20% - 10px);
    cursor: pointer;
    transition: transform 0.3s;
    border-radius: 5px;
  }

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  height: 50px;
}

.offers-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center; /* Centrer les cartes lorsqu'il y en a moins que le nombre de colonnes */
}

.offer-card {
  position: relative;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff; /* Ajout d'un fond blanc pour les cartes */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.offer-card ul {
  flex-grow: 1;
}

.pay-button {
  display: block;
  padding: 10px;
  background-color: #f8f29d;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  text-align: center; /* Centrer le texte dans le bouton */
}

.pay-button:hover {
  background-color: #3996ad;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .offers-container {
    grid-template-columns: repeat(2, 1fr); /* 2 cartes par ligne */
  }
}

@media (max-width: 768px) {
  .offers-container {
    grid-template-columns: 1fr; /* 1 carte par ligne */
  }
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Style pour les cartes ayant l'attribut bestSeller à True */
.best-seller {
  border: 3px solid #007bff;
}

/* Style pour les boutons ayant le texte "Tester" */
.tester-button {
  background-color: #007bff;
  color: #f8f29d; /* Pour assurer la lisibilité du texte sur le fond bleu */
}

.tester-button:hover {
  background-color: #007bff;
}

ul {
  list-style-type: none;
  line-height: 1.5;
}

a {
  text-decoration: none; /* Enlève le soulignement par défaut */
  color: #007bff; /* Couleur des liens */
}

a:hover {
  text-decoration: underline; /* Ajoute un soulignement au survol */
}

.error {
  color: red;
  font-size: 0.8em;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f4;
}

.info-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.info-card h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 16px;
}

.info-card p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  margin-bottom: 16px;
}

@media (max-width: 600px) {
  .info-card h2 {
    font-size: 20px;
  }

  .info-card p {
    font-size: 14px;
  }
}

/* Style pour les cartes ayant l'attribut bestSeller à True */
.best-seller {
  border: 2px solid #007bff;
}

/* Style pour le badge "Best Seller" */
.best-seller-badge {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  font-size: 0.9rem;
  border-radius: 5px;
  font-weight: bold;
}

.bullet {
  width: 8px;
  height: 8px;
  background-color: #007bff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.our-selection {
  border: 2px solid #ffeb3b;
}

.our-selection-badge{
  position: absolute;
  top: -10px;
  right: 10px;
  background-color: #ffeb3b;
  color: black;
  padding: 5px 10px;
  font-size: 0.9rem;
  border-radius: 5px;
  font-weight: bold;
}

label {
  display: block;
  font-size: 1.2em;
  margin-bottom: 15px;
  text-align: center;
}
</style>
  