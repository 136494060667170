<template>
    <div class="form-container">
      <h1>Nous contacter</h1>
      <p>Remplissez ce formulaire pour nous faire part de vos besoins. Nous vous contacterons dans les plus brefs délais.</p>
      <form @submit.prevent="submitForm" class="contact-form">
        <div class="form-group">
          <label for="companyName">Nom de l'entreprise :</label>
          <input v-model="companyName" id="companyName" placeholder="Votre entreprise" required />
        </div>
  
        <div class="form-group">
          <label for="contactPerson">Personne à contacter :</label>
          <input v-model="contactPerson" id="contactPerson" placeholder="Votre nom" required />
        </div>
  
        <div class="form-group">
          <label for="contactEmail">Email de contact :</label>
          <input v-model="contactEmail" id="contactEmail" type="email" placeholder="exemple@domaine.com" required />
        </div>
  
        <div class="form-group">
          <label for="contactPhone">Téléphone (optionnel) :</label>
          <input v-model="contactPhone" id="contactPhone" type="tel" placeholder="+33 6 12 34 56 78" />
        </div>
  
        <div class="form-group">
          <label for="requestInfo">Informations sur le besoin :</label>
          <textarea v-model="requestInfo" id="requestInfo" placeholder="Décrivez vos besoins" rows="5" required></textarea>
        </div>
  
        <button type="submit" class="submit-button">Envoyer</button>
        <div v-if="submitted" class="notification success">
          Formulaire soumis avec succès ! Nous vous contacterons bientôt.
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'EntrepriseView',
    data() {
      return {
        companyName: '',
        contactPerson: '',
        contactEmail: '',
        contactPhone: '',
        requestInfo: '',
        submitted: false,
      };
    },
    methods: {
      async submitForm() {
        const payload = {
          company_name: this.companyName,
          contact_person: this.contactPerson,
          contact_email: this.contactEmail,
          contact_phone: this.contactPhone,
          request_info: this.requestInfo,
        };
        try {
          await axios.post('/entreprise/contact/', payload);
          this.submitted = true;
          setTimeout(() => this.submitted = false, 8000); 
        } catch (error) {
          console.error('Erreur lors de la soumission :', error);
          alert('Une erreur est survenue, veuillez réessayer.');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .form-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #333;
  }
  
  p {
    margin-bottom: 20px;
    color: #666;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  label {
    display: block;
    font-size: 1.1em;
    margin-bottom: 5px;
    color: #555;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 2px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s;
  }
  
  input:focus, textarea:focus {
    border-color: #007BFF;
    outline: none;
  }
  
  .submit-button {
    padding: 10px 15px;
    font-size: 1.2em;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .submit-button:active {
    background-color: #003d80;
  }

  .notification {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 1em;
    width: 100%;
    max-width: 600px;
    text-align: center;
  }
  
  .notification.success {
    background-color: #0056b3;
  }
  </style>
  