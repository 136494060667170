<template>
    <div class="article-container">
      <div v-if="article">
        <h1 class="article-title">{{ article.title }}</h1>
        <p class="article-date">{{ new Date(article.created_at).toLocaleDateString() }}</p>
        <div class="article-content" v-html="article.content"></div>
        <button @click="goBack" class="back-button">Retour</button>
      </div>
      <div v-else>
        <p class="loading-message">Chargement...</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  const baseUrl = 'http://127.0.0.1:8000'; // Assurez-vous que cette URL est correcte
  
  export default {
    name: 'ArticleView',
    data() {
      return {
        article: null,
      };
    },
    created() {
      this.fetchArticle();
    },
    methods: {
      async fetchArticle() {
        const slug = this.$route.params.slug;
        try {
          const response = await axios.get(`${baseUrl}/blog/${slug}/`);
          this.article = response.data;
        } catch (error) {
          console.error('Erreur lors de la récupération de l\'article:', error);
        }
      },
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>
  
  <style scoped>
  .article-container {
    padding: 30px;
    max-width: 900px;
    margin: auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .article-title {
    font-size: 2.5em;
    margin-bottom: 15px;
    color: #333;
  }
  
  .article-date {
    font-size: 1em;
    color: #777;
    margin-bottom: 25px;
  }
  
  .article-content {
    font-size: 1.2em;
    line-height: 1.6;
    color: #444;
  }
  
  .back-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  
  .loading-message {
    font-size: 1.2em;
    color: #555;
    text-align: center;
    margin-top: 50px;
  }
  </style>
  