<template>
  <div>
    <div class="legal-links">
      <a href="/" class="legal-link">Home</a>
      <a href="/gallery" class="legal-link">Galerie</a>
      <a href="/blog" class="legal-link">Blog</a>
    </div>

    <router-view></router-view>

    <div class="legal-links">
      <a href="/cgv" class="legal-link">Conditions Générales de Vente (CGV)</a>
      <a href="/mentions-legales" class="legal-link">Mentions Légales</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Ombre portée douce */
  background-color: white;
  border-radius: 10px; /* Coins arrondis */
  padding: 20px; /* Espace intérieur */
  max-width: 80%;
  margin: auto;
  margin-top: 60px;
}

html{
  background-color: #f8f29d; /* Couleur de fond du conteneur */
}

.legal-links {
  margin-top: 20px;
}

.legal-link {
  display: inline-block;
  margin-right: 20px;
  text-decoration: none;
  color: #007bff; /* Couleur du lien */
  font-weight: bold;
}

.legal-link:hover {
  text-decoration: underline;
}

</style>
