<template>
    <div class="mentions-legales-view">
    <h1>Mentions Légales</h1>

    <h2>1. Éditeur du Site</h2>
    <p>
      Le site Cortus7families est édité par Hina & Cortus OÜ, au capital de 2 500 euros, dont le siège social est situé au Harju maakond, Tallinn, Kesklinna linnaosa, Tartu mnt 67/1-13b, 10115.
    </p>
    <p>
      Immatriculée au Registre du Commerce et des Sociétés de Tallin sous le numéro 16556127, représentée par son représentant légal, en qualité de Directeur.
    </p>

    <h2>3. Hébergement</h2>
    <p>
      Le site est hébergé par Gandi SAS, dont le siège social est situé au 63-65 boulevard Masséna, 75013 Paris.
    </p>

    <p>
      Site web : https://Cortus7families.fr
    </p>

    <h2>4. Propriété Intellectuelle</h2>
    <p>
      Le site, ainsi que son contenu (textes, images, graphismes, logos, vidéos, etc.), sont la propriété exclusive de Hina & Cortus OÜ ou de ses partenaires. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de l'entreprise.
    </p>

    <h2>5. Données Personnelles</h2>
    <p>
      Conformément à la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, modifiée par le Règlement Général sur la Protection des Données (RGPD) du 27 avril 2016, vous disposez d'un droit d'accès, de rectification, de suppression et d'opposition aux données personnelles vous concernant. Pour exercer ces droits, vous pouvez nous contacter à l'adresse suivante : contact[@]hinacortus.com .
    </p>
    <p>
      Pour plus d'informations, veuillez consulter notre <a href="">politique de confidentialité</a>.
    </p>

    <h2>6. Cookies</h2>
    <p>
      Le site Cortus7families.fr utilise des cookies pour améliorer l'expérience utilisateur, analyser le trafic et personnaliser les contenus. Vous pouvez configurer votre navigateur pour refuser les cookies ou être informé avant leur installation. Pour plus d'informations, veuillez consulter notre <a href="">politique de cookies</a>.
    </p>

    <h2>7. Liens Hypertextes</h2>
    <p>
      Le site Cortus7families.fr peut contenir des liens hypertextes vers d'autres sites web. Nous n'exerçons aucun contrôle sur ces sites et déclinons toute responsabilité quant à leur contenu. L'accès à ces sites se fait aux risques et périls de l'utilisateur.
    </p>

    <h2>8. Responsabilité</h2>
    <p>
      Hina & Cortus OÜ ne saurait être tenue responsable des dommages directs et indirects causés au matériel de l'utilisateur, lors de l'accès au site Cortus7families.fr, et résultant soit de l'utilisation d'un matériel ne répondant pas aux spécifications indiquées, soit de l'apparition d'un bug ou d'une incompatibilité.
    </p>
    <p>
      Hina & Cortus OÜ ne pourra également être tenue responsable des dommages indirects consécutifs à l'utilisation du site Cortus7families.fr.
    </p>

    <h2>9. Droit Applicable et Attribution de Juridiction</h2>
    <p>
      Tout litige en relation avec l'utilisation du site Cortus7families.fr est soumis au droit. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Tallin.
    </p>

    <h2>10. Contact</h2>
    <p>
      Pour toute question relative aux mentions légales du site, vous pouvez nous contacter à l'adresse suivante : contact[@]hinacortus.com.
    </p>
  </div>

  </template>
  
  <script>
  export default {
    name: 'MentionsLegalesView',
  };
  </script>
  
  <style scoped>
  .mentions-legales-view {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  p {
    line-height: 1.6;
    margin-bottom: 15px;
  }
  </style>
  