<template>
    <div class="blog-container">
      <h1 class="blog-title">Blog</h1>
      <div class="filter-container">
        <label for="category" class="filter-label">Filtrer par catégorie:</label>
        <select v-model="selectedCategory" @change="filterByCategory" class="filter-select">
          <option value="">Toutes catégories</option>
          <option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select>
      </div>
      <ul class="article-list">
        <li v-for="article in articles" :key="article.id" class="article-item">
          <router-link :to="'/blog/' + article.slug" class="article-title">{{ article.title }}</router-link>
          <p class="article-date">{{ new Date(article.created_at).toLocaleDateString() }}</p>
        </li>
      </ul>
    </div>
  </template>
  
  <style scoped>
  .blog-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .blog-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .filter-container {
    margin-bottom: 20px;
    display: contents;
    align-items: center;
    justify-content: flex-start;
  }
  
  .filter-label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .filter-select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .article-list {
    list-style: none;
    padding: 0;
  }
  
  .article-item {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s ease;
  }
  
  .article-item:last-child {
    border-bottom: none;
  }
  
  .article-item:hover {
    background-color: #f0f0f0;
  }
  
  .article-title {
    font-size: 1.5em;
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .article-title:hover {
    color: #0056b3;
  }
  
  .article-date {
    color: #888;
    font-size: 0.9em;
  }
  </style>
  

<script>
import axios from 'axios';
import Cookies from 'js-cookie';

axios.defaults.baseURL = 'http://127.0.0.1:8000/';
const csrftoken = Cookies.get('csrftoken');
if (!csrftoken) {
  console.error('CSRF token is not defined in cookies');
}
axios.defaults.headers.common['X-CSRFToken'] = csrftoken;

export default {
  name: 'BlogView',
  data() {
    return {
      articles: [],
      categories: [],
      selectedCategory: '',
    };
  },
  created() {
    this.fetchCategories();
    this.fetchArticles();
  },
  methods: {
    async fetchArticles() {
      try {
        const response = await axios.get('/blog/', {
          params: {
            category: this.selectedCategory,
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        this.articles = response.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des articles:', error);
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get('/blog/categories/');
        this.categories = response.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des catégories:', error);
      }
    },
    filterByCategory() {
      this.fetchArticles();
    },
  },
};
</script>

