<template>
    <div class="steps-container">
      <h1>Comment ça marche ?</h1>
      <div class="step">
        <div class="step-content">
          <h2>Étape 1: Configuration</h2>
          <p>
            Commencez par configurer votre jeu de cartes. Définissez le nombre de familles et le nombre de personnages par famille.
          </p>
        </div>
      </div>
  
      <div class="step">
        <div class="step-content">
          <h2>Étape 2: Personnalisation</h2>
          <p>
            Personnalisez chaque famille et chaque personnage. Donnez-leur des noms uniques et ajoutez des descriptions détaillées.
          </p>
        </div>
      </div>
  
      <div class="step">
        <div class="step-content">
          <h2>Étape 3: Prêt à lancer l'impression</h2>
          <p>
            Découvez votre jeu et <br/> Cliquez sur "Envoyer à l'impression" pour recevoir votre jeu de cartes à la prochaine livraison.
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HowToUse',
  };
  </script>
  
  <style scoped>
  .steps-container {
    max-width: 800px;
    margin: 01rem auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 0.07rem black solid;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .steps-container h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }
  
  .step {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .step-content {
    flex: 1;
  }
  
  .step-content h2 {
    margin-top: 0;
    color: #333;
  }
  
  .step-content p {
    color: #555;
    line-height: 1.6;
  }
  
  .step-content img {
    max-width: 100%;
    border-radius: 4px;
    margin-top: 10px;
  }
  </style>
  