<template>
    <div class="gallery-container">
      <h1>Galerie des Jeux de 7 Familles</h1>

      <p>Parcourez les derniers jeux de familles que nous avons créés pour vous !</p>
  
      <div class="gallery-grid">
        <div v-for="(group, taskId) in groupedGames" :key="taskId" class="fan-group">
          <!-- <p>Éventail ref: {{ taskId }}</p> -->
          <div class="fan-container">
            <div
              v-for="(game, index) in group"
              :key="index"
              class="fan-item"
              @click="selectGame(game.image_url)"
              :style="{ transform: `rotate(${index * (360 / group.length)}deg)` }"
            >
              <img :src="formatImageUrl(game.image_url)" :alt="game.name" class="game-thumbnail" />
            </div>
          </div>
        </div>
      </div>
  
      <div class="selected-game" v-if="selectedImage" @click.self="deselectGame">
        <div class="selected-image-container">
          <button class="close-button" @click.stop="deselectGame">&times;</button>
          <img :src="selectedImage" alt="Selected Game" class="selected-image" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'GalleryView',
    data() {
      return {
        games: [], // Liste des jeux (jeux de 7 familles)
        selectedImage: null, // Image sélectionnée
      };
    },
    computed: {
      groupedGames() {
        return this.games.reduce((groups, game) => {
          const taskId = game.task_id || 'no-task';
          if (!groups[taskId]) {
            groups[taskId] = [];
          }
          groups[taskId].push(game);
          return groups;
        }, {});
      }
    },
    methods: {
      async fetchGames() {
        try {
          const response = await axios.get('/my_model/');
          this.games = response.data; // Récupère les jeux depuis l'API
        } catch (error) {
          console.error('Erreur lors de la récupération des jeux:', error);
        }
      },
      // PAS NECESSAIRE EN PRODUCTION ??? MAIS UTILE EN DEVELOPPEMENT LOCAL
      formatImageUrl(url) {
        // Assure que l'URL est une chaîne valide avant de la modifier
        if (url && typeof url === 'string') {
          //return url.replace('static/', 'http://127.0.0.1:8000/');
          return url.replace('static/', 'https://cortus7families.fr/static/');
        }
        return url; // Retourne l'URL inchangée si elle n'est pas valide
      },
      // PAS NECESSAIRE EN PRODUCTION ???
      selectGame(imageUrl) {
        this.selectedImage = this.formatImageUrl(imageUrl); // Définit l'image sélectionnée
      },
      deselectGame() {
        this.selectedImage = null; // Réinitialise l'image sélectionnée
      }
    },
    mounted() {
      this.fetchGames(); // Appel à l'API lors du montage du composant
    },
  };
  </script>
  
  <style scoped>
  .gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    text-align: center;
    padding: 20px;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 30px;
  }
  
  .fan-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .fan-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
    perspective: 1000px;
  }
  
  .fan-item {
    position: absolute;
    transform-origin: bottom center;
    transition: transform 0.3s ease;
    cursor: pointer;
    width: 150px;
    max-width: 200px;
  }
  
  .fan-item:hover {
    transform: scale(1.1);
  }
  
  .game-thumbnail {
    width: 100px;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .selected-game {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .selected-image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .selected-image {
    max-width: 90vw;
    max-height: 90vh;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 2em;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #f00;
  }
  
  @media (max-width: 768px) {
    .game-thumbnail {
      width: 80px;
      height: 120px;
    }
  
    .selected-image {
      max-height: 70vh;
    }
  }
  </style>
  