<template>
  <div class="container">
    <h1>Parcourez vos familles avant de lancer l'impression !</h1>

    <div v-if="loading">Loading...</div>
      <div v-if="error" class="error">{{ error }}</div>
      <div v-if="task">
        <p>{{ task.name }}</p>
        <p>{{ task.description }}</p>
    </div>
    

    <!-- <h1>Données transmises :</h1> -->
    <div v-if="imageData && imageData.length > 0 && backgroundImagesGenerated">
      <div class="image-container">
        <img v-for="(image, index) in formattedImageData" 
            :key="index" 
            :src="image.image_url" 
            :alt="image.name" 
            height="300" 
            class="image-item">
      </div>
    </div>
    <div v-else-if="imageData === null || (Array.isArray(imageData) && imageData.length === 0) && backgroundImagesGenerated">
        <h2><p>Vous êtes arrrivez trop tôt, merci de revenir dans quelques minutes.</p></h2>
    </div>

    <div v-else>
      Une erreur est survenue. Nous revenons vers vous ou vous pouvez nous contacter sur contact@hinacortus.com
      <p>No images to display.</p>
    </div>

    Voici le dos de votre jeu de carte
    <p><img :src="backgroundImagesGenerated" height="250" class="image-item" alt="Background image"></p>

    <form @submit.prevent="validateAndPrint">
      <div class="form-group">
        <label for="prenom">Prénom:</label>
        <input type="text" id="prenom" v-model="form.prenom" required />
      </div>
      <div class="form-group">
        <label for="nom">Nom:</label>
        <input type="text" id="nom" v-model="form.nom" required />
      </div>
      <div class="form-group">
          <label for="email">Confirmation de votre Email :</label>
          <input type="email" id="email" v-model="form.email" @input="validateEmail" required />
          <span v-if="emailError" class="error">{{ emailError }}</span>
      </div>
      <div class="form-group">
          <label for="address">Adresse Postale:</label>
          <input type="text" id="address" v-model="form.address" required />
      </div>
      <div class="form-group">
          <label for="confirm-address">Confirmer l'Adresse Postale:</label>
          <input type="text" id="confirm-address" v-model="form.confirmAddress" required />
          <span v-if="addressMismatchError" class="error">{{ addressMismatchError }}</span>
      </div>

      <!-- Case à cocher et texte sur la même ligne -->
      <div class="form-group checkbox-group">
        <input type="checkbox" id="accept-terms" v-model="form.acceptTerms" />
        <label for="accept-terms" class="checkbox-label">
          J'accepte les <a href="#" @click.prevent="showTerms = !showTerms">conditions générales</a>
        </label>
      </div>

      <!-- Conditions générales affichées -->
      <div v-if="showTerms" class="terms-conditions">
        <p>
          En validant votre commande, vous reconnaissez que plus aucune modification ne sera possible et que vous acceptez <a href="https://cortus7families.fr/cgv" target="_blank">nos conditions générales de ventes</a>.
        </p>
      </div>

      <button type="submit" class="form-button" :disabled="!form.acceptTerms">Valider et Lancer l'impression</button>

      <div v-if="commandeEffectuee" class="message">
        Votre commande est partie à l'impression, vous allez recevoir un email de confirmation.
      </div>
      
    </form>
    
  </div>
</template>

  <script>
  import axios from 'axios';
  import Cookies from 'js-cookie';

  // Configurer le baseURL pour axios
  axios.defaults.baseURL = 'http://127.0.0.1:8000/'; 
  //axios.defaults.baseURL = 'https://cortus7families.fr/'
  const baseUrl = 'http://127.0.0.1:8000'; //local
  //const baseUrl = 'https://cortus7families.fr'; 

  // Récupérer le token CSRF du cookie
  const csrftoken = Cookies.get('csrftoken');
  if (!csrftoken) {
    console.error('CSRF token is not defined in cookies');
  }

  // Ajouter le CSRF token à toutes les requêtes axios
  axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
  
  export default {
    name: 'VisualComponent',
    data() {
      return {
        form: {
          prenom: '',
          nom: '',
          email: '',
          address: '',
          confirmAddress: '',
          offer: {},
          acceptTerms: false, // Case à cocher pour accepter les conditions
        },
        emailError: '',
        /* families: [
        { 
            name: 'Cartoon', 
            characters: [
              { name: 'Thaïland', image: 'img/Woman_Thailand_Cartoon.png', description: 'Description 1' }, 
              { name: 'Vietnam', image: 'img/Woman_Vietnam_Cartoon.png', description: 'Description 1' }, 
              { name: 'Lao', image: 'img/Woman_Lao_Cartoon.png', description: 'Description 1' }, 
              { name: 'Korea', image: 'img/Woman_Korea_Cartoon.png', description: 'Description 1' }, 
              { name: 'Japan', image: 'img/Woman_Japan_Cartoon.png', description: 'Description 1' }, 
              { name: 'China', image: 'img/Woman_China_Cartoon.png', description: 'Description 1' }, 
              { name: 'Cambodia', image: 'img/Woman_Cambodia_Cartoon.png', description: 'Description 1' }
            ] 
          },
          { 
            name: 'Real World', 
            characters: [
                { name: 'Thaïland', image: 'img/Woman_Thailand_Real-world.png', description: 'Description Thai real world' }, 
                { name: 'Vietnam', image: 'img/Woman_Vietnam_Real-world.png', description: 'Description 1' }, 
                { name: 'Lao', image: 'img/Woman_Lao_Real-world.png', description: 'Description 1' }, 
                { name: 'Korea', image: 'img/Woman_Korea_Real-world.png', description: 'Description 1' }, 
                { name: 'Japan', image: 'img/Woman_Japan_Real-world.png', description: 'Description 1' }, 
                { name: 'China', image: 'img/Woman_China_Real-world.png', description: 'Description 1' }, 
                { name: 'Cambodia', image: 'img/Woman_Cambodia_Real-world.png', description: 'Description 1' }
            ] 
          },

        ], */ // Your families data
        hoveredFamilyIndex: null,
        hoveredCharacterIndex: null,
        inputFamilyIndex: null,
        inputCharIndex: null,
        modificationText: '',
        hasData: false,
        // Valeurs par défaut
        defaultImageData: [],
        defaultBackgroundImagesGenerated: {},
        imageData: null,
        backgroundImagesGenerated: "http://127.0.0.1:8000/background.png",
        commandeEffectuee: false,
        loading: true,
        error: null,
        task: '',
        addressMismatchError: '',
        showTerms: false, // Variable pour afficher/masquer les conditions générales
      };
    },
    created() {
      this.fetchTask();
    },
    methods: {
      async fetchTask() {
        const taskId = this.$route.params.taskId;
        try {
          //const response = await axios.get(`${baseUrl}/api/visual2/${taskId}`);
          const response = await fetch(`${baseUrl}/api/visual2/${taskId}`, {
            method: 'GET',
            credentials: 'include', // Pour inclure les cookies de session
            headers: {
              'X-CSRFToken': Cookies.get('csrftoken'),
              'Content-Type': 'application/json',
            },
          });
          // Vérifie d'abord si la requête a réussi
          if (!response.ok) {
              console.error('Erreur dans la requête:', response.status);
              return;
          }

          // Parse le JSON de la réponse
          const data = await response.json();

          // Assigne les données des images à `this.imageData`
          this.imageData = data.image_data;

          // Affiche les données pour vérification
          //console.log('Données des images:', this.imageData);
        } catch (err) {
          this.error = 'Une erreur est survenue. Merci de nous contacter sur contact@hinacortus.com .';
          console.error(err);
        } finally {
          this.loading = false;
        }
      },
      validateEmail() {
            // Validation de l'email
            if (!this.isValidEmail(this.form.email)) {
                this.emailError = 'L\'email n\'est pas valide.';
            } else {
                this.emailError = '';
            }
        },
        validateAddress() {
            // Validation de l'adresse postale
            if (this.form.address !== this.form.confirmAddress) {
                this.addressMismatchError = 'Les adresses postales ne correspondent pas.';
            } else {
                this.addressMismatchError = '';
            }
        },
        isValidEmail(email) {
            // Fonction simple pour vérifier la validité de l'email
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(email);
        },
      //logData() {
        //this.backgroundImagesGenerated = this.$route.query.backgroundImagesGenerated;
        //console.log('backgroundImagesGenerated: ', this.backgroundImagesGenerated);

        //console.log('Type de imageData :', typeof this.imageData)
        //console.log('imageData :', this.imageData || this.defaultImageData)
      //},
      /*
      goToSettingsView() {
        this.$router.push({ name: 'Settings' });
      },
      */
      async validateAndPrint() {
        if (this.emailError) {
          alert('Veuillez corriger les erreurs avant de soumettre le formulaire.');
          return;
        }
        if (this.form.prenom && this.form.nom && this.form.email && this.form.address) {
          // Lancer l'impression ou toute autre action après la validation
          //alert('Formulaire validé. Lancement de l\'impression...');
          //window.print();
        } else {
          alert('Veuillez remplir tous les champs.');
        }

        //console.log('this.form: ', this.form);
          const response = await axios.post('/api/userform/', this.form, {
            headers: {
              'X-CSRFToken': Cookies.get('csrftoken'),
              'Content-Type': 'application/json'
            }
          });
          //console.log('Form submitted successfully:', response.data);

          if (response.data.message === 'Success') {
            // Mettre à jour commandeEffectuee à true pour afficher le message
            this.commandeEffectuee = true;
          } else {
            // Gérer les autres réponses si nécessaire
            console.error('Erreur:', response.data);
          }
      },
      hoverCharacter(familyIndex, charIndex) {
        this.hoveredFamilyIndex = familyIndex;
        this.hoveredCharacterIndex = charIndex;
      },
      resetHover() {
        this.hoveredFamilyIndex = null;
        this.hoveredCharacterIndex = null;
      },
      isHovered(familyIndex, charIndex) {
        return this.hoveredFamilyIndex === familyIndex && this.hoveredCharacterIndex === charIndex;
      },
      showInput(familyIndex, charIndex) {
        this.inputFamilyIndex = familyIndex;
        this.inputCharIndex = charIndex;
      },
      showInputBox(familyIndex, charIndex) {
        return this.inputFamilyIndex === familyIndex && this.inputCharIndex === charIndex;
      },
      async generateNewImage(familyIndex, charIndex) {
        const response = await fetch('/api/generate/newimage', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            familyIndex,
            charIndex,
            modificationText: this.modificationText
          })
        });
        const data = await response.json();
        // Handle the response data
        //console.log(data);
        // Assuming the API returns the new image URL
        this.families[familyIndex].characters[charIndex].image = data.newImageUrl;
        // Reset input fields
        this.inputFamilyIndex = null;
        this.inputCharIndex = null;
        this.modificationText = '';
      },
      getStaticUrl(path) {
        return `${process.env.VUE_APP_BASE_URL}/static/${path}`;
      },
    },
    computed: {
      formattedImageData() {
        return this.imageData.map(image => ({
          ...image,
          image_url: image.image_url.replace('static/', 'http://127.0.0.1:8000/')
        }));
      },
      infoBoxStyle() {
        if (this.hoveredCharacterIndex !== null) {
          const characterCardWidth = 200; // Width of the character card
          const containerWidth = 200; // Width of the container for the info box
          const characterLeftPosition = this.hoveredCharacterIndex * characterCardWidth; // Left position of the hovered character card
          const containerLeftPosition = characterLeftPosition + characterCardWidth; // Left position of the container relative to the hovered character card
  
          // Check if the container goes beyond the right edge of the viewport
          const isContainerTooRight = containerLeftPosition + containerWidth > window.innerWidth;
  
          return {
            top: '0',
            [isContainerTooRight ? 'right' : 'left']: isContainerTooRight ? `${window.innerWidth - characterLeftPosition}px` : `${containerLeftPosition}px`
          };
        }
        return {};
      },
    },
    watch: {
        'form.email': function() {
            this.validateEmail();
        },
        'form.address': function() {
            this.validateAddress();
        },
        'form.confirmAddress': function() {
            this.validateAddress();
        }
    }
  };
  </script>

<style scoped>
.container {
  text-align: center;
}
.families-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.family-stack {
  margin: 10px;
}
.character-wrapper {
  position: relative;
  display: inline-block;
}
.image-container {
  position: relative;
}
.character-card {
  width: 150px;
  height: auto;
  cursor: pointer;
}
.hovered {
  border: 2px solid blue;
}
.hover-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  z-index: 1;
  width: 200px;
}
.info-box {
  position: absolute;
  top: 0;
  left: 210px; /* 200px for the width of the image + 10px spacing */
  background-color: gray;
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 200px; /* Fixed width for the info box */
  z-index: 20;
}
.input-container {
  /* position: absolute; */
  top: 0;
  left: 100%;
  background: white;
  border: 1px solid black;
  padding: 10px;
  z-index: 10;
}

.styled-button {
  padding: 10px 20px; /* Adjust padding as needed */
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Remove default border */
  border-radius: 25px; /* Make the button rounded */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 1rem; /* Adjust font size */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transitions */
}

.styled-button:hover {
  background-color: #0056b3; /* Darker background on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-item {
  max-width: 200px;
  margin: 10px;
}

.form-container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group {
  margin-bottom: 15px;
}

.form-button {
  margin: 10px;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  height: 50px;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Optionnel, pour espacer les images */
}

.image-item {
  transition: transform 0.3s ease; /* Transition fluide */
  cursor: pointer; /* Change le curseur pour indiquer que l'image est interactive */
}

.image-item:hover {
  transform: scale(1.8); /* Augmente la taille de l'image à 110% */
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-label {
  margin-left: 8px;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.terms-conditions {
  background-color: #f8f8f8;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
}

.form-button {
  margin-top: 15px;
  padding: 10px 20px;
}

.error {
  color: red;
}

.message {
  margin-top: 20px;
  padding: 10px;
  background-color: #dff0d8;
  color: #3c763d;
}

.form-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>