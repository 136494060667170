// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/components/HomeView.vue';
import SettingsView from '@/components/SettingsView.vue';
import VisualView from '@/components/VisualView.vue';

import BlogView from '@/components/BlogView.vue';
import ArticleView from '@/components/ArticleView.vue';
import GalleryView from '@/components/GalleryView.vue';
import EntrepriseView from '@/components/EntrepriseView.vue';
import InpaintingView from '@/components/InpaintingView.vue';

import CgvView from '@/components/CgvView.vue';
import MentionsLegalesView from '@/components/MentionsLegalesView.vue';
import ThanksView from '@/components/ThanksView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    //path: '/app/:user_id/:order_id/:familyCount/:characterCount',
    path: '/app2/:familyCount/:characterCount',
    name: 'Settings',
    component: SettingsView,
    props: true, // Permet de passer les paramètres de route en tant que props au composant
  },
  {
    //path: '/visual',
    path: '/visual2/:taskId',
    name: 'visual',
    component: VisualView,
    /*props: (route) => ({
      imageData: route.params.imageData,
      backgroundImagesGenerated: route.params.backgroundImagesGenerated
    })*/
  },

  {
    path: '/blog',
    name: 'Blog',
    component: BlogView,
  },
  {
    path: '/blog/:slug',
    name: 'Article',
    component: ArticleView,
    props: true,
  },
  { path: '/gallery', name: 'Gallery', component: GalleryView, },
  { path: '/entreprise', name: 'Entreprise', component: EntrepriseView, },
  { path: '/inpainting', name: 'Inpainting', component: InpaintingView, },

  { path: '/cgv', name: 'CGV', component: CgvView, },
  { path: '/mentions-legales', name: 'MentionsLegales', component: MentionsLegalesView, },
  { path: '/thanks', name: 'Thanks', component: ThanksView, },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
