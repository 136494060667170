<template>
    <div class="waitlist-container">
      <h1>Rejoignez notre liste d'attente</h1>
      <h2>Et creez bientôt vos propres jeux de 7 familles basés sur vos vraies photos.</h2>
      <form @submit.prevent="submitForm" class="waitlist-form">
        <div class="form-group">
          <label for="firstName">Prénom:</label>
          <input v-model="firstName" id="firstName" type="text" required />
        </div>
  
        <div class="form-group">
          <label for="lastName">Nom:</label>
          <input v-model="lastName" id="lastName" type="text" required />
        </div>
  
        <div class="form-group">
          <label for="email">Email:</label>
          <input v-model="email" id="email" type="email" required />
        </div>
  
        <div class="form-group">
          <label for="phone">Téléphone (option):</label>
          <input v-model="phone" id="phone" type="tel" />
        </div>
  
        <button type="submit" class="submit-button">Envoyer</button>
      </form>
      <div v-if="submitted" class="notification success">
        Vous êtes bien inscrit à notre liste d'attente pour faire partis des premiers utilisateurs des jeux de 7 familles basés sur de réelles photos.
        Nous revenons vite vers vous.
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'InpaintingView',
    data() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        submitted: false,
      };
    },
    methods: {
      async submitForm() {
        const payload = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone: this.phone,
        };
        try {
          await axios.post('/inpainting/waitlist/', payload);
          this.submitted = true;
          this.resetForm();
          setTimeout(() => this.submitted = false, 8000); // Hide notification after 5 seconds
        } catch (error) {
          console.error('Error submitting form:', error);
          alert('There was an error submitting your form. Please try again.');
        }
      },
      resetForm() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phone = '';
      },
    },
  };
  </script>
  
  <style scoped>
  .waitlist-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .waitlist-form {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 600px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1em;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .notification {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 1em;
    width: 100%;
    max-width: 600px;
    text-align: center;
  }
  
  .notification.success {
    background-color: #0056b3;
  }
  </style>
  