<template>
    <div class="cgu-view">
    <h1>Conditions Générales de Vente (CGV)</h1>

    <h2>1. Introduction</h2>
    <p>Bienvenue sur notre plateforme de création de 7 familles propulsée par l'intelligence artificielle générative. Ces Conditions Générales de Vente (CGV) régissent l'accès et l'utilisation de nos services. Veuillez les lire attentivement avant d'utiliser notre site.</p>

    <h2>2. Acceptation des Conditions</h2>
    <p>En accédant à notre service, vous acceptez d'être lié par ces CGV. Si vous n'acceptez pas ces termes, vous ne devez pas utiliser notre service.</p>

    <h2>3. Description des Services</h2>
    <p>Notre service SaaS permet aux utilisateurs de jouer à des jeux de cartes en ligne générés par une intelligence artificielle. Nous proposons différents niveaux d'abonnement avec des fonctionnalités variées.</p>

    <h2>4. Abonnements et Tarifs</h2>
    <p>Nous proposons plusieurs formules. Les détails des tarifs et des fonctionnalités associées sont disponibles sur notre site. Les prix peuvent être modifiés à tout moment, mais les changements n'affecteront pas les abonnements en cours jusqu'à leur renouvellement.</p>

    <h2>5. Paiement</h2>
    <p>Les paiements doivent être effectués via les méthodes de paiement sécurisées disponibles sur notre site. Tous les frais sont payables à l'avance et ne sont pas remboursables sauf mention contraire.</p>

    <h2>6. Utilisation du Service</h2>
    <p>Vous vous engagez à utiliser notre service conformément aux lois en vigueur et à ne pas l'utiliser à des fins illicites ou abusives.</p>

    <h2>7. Limitation de Responsabilité</h2>
    <p>Notre responsabilité est limitée à la fourniture du service tel que décrit. Nous ne sommes pas responsables des dommages indirects ou imprévus liés à l'utilisation de notre service.</p>

    <h2>8. Propriété Intellectuelle</h2>
    <p>Tout le contenu de notre service, y compris les jeux, les graphiques, les textes et les algorithmes, est protégé par des droits de propriété intellectuelle et ne peut être utilisé sans notre autorisation.</p>

    <h2>9. Modifications des CGV</h2>
    <p>Nous nous réservons le droit de modifier ces CGV à tout moment. Les modifications seront publiées sur notre site et entreront en vigueur immédiatement. Il vous incombe de consulter régulièrement les CGV.</p>

    <h2>10. Droit Applicable</h2>
    <p>Ces CGV sont régies par les lois du pays dans lequel notre entreprise est enregistrée. Tout litige sera soumis à la compétence exclusive des tribunaux de ce pays.</p>

    <h2>11. Contact</h2>
    <p>Pour toute question concernant ces CGV, veuillez nous contacter à l'adresse email suivante : contact[@]hinacortus.com.</p>
  </div>

  </template>
  
  <script>
  export default {
    name: 'CGUView',
  };
  </script>
  
  <style scoped>
  .cgu-view {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  p {
    line-height: 1.6;
    margin-bottom: 15px;
  }
  </style>
  